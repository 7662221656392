<template lang="pug">
div.flashing_countdown_container(v-if="running")
  div.wrapper
    div.count.vertical-center(v-html="readyCount")
    div.overlay(v-bind:class="{fill: blink}")
</template>

<script>


export default {
  name: 'FlashingCountdown',
  props: {
    startingCount: {
      type: Number,
      default: 3,
    },
    recordingLimit: {
      type: Number,
      default: 0,
    },
    run: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    readyCount () {
      if (this.count > this.startingCount) return this.recordingLimit <= 0 ? "Ready?" : "READY?<br><br>You have "+Math.round(this.recordingLimit/60) + " minute."
      if (this.count == 0) return "GO!"
      return this.count
    },
  },
  watch: {
    count: function () {
      if (this.count <= -1) { this.timerEnd() }
    },
    run: function () {
      this.running = this.run
      if (this.run) {
        this.startTimer()
      } else {
        this.clearTimer()
      }
    }
  },
  data() {
    return {
      blink: false,
      count: 0,
      timerInterval: null,
      running: false,
    }
  },
  methods: {
    clearTimer () {
      clearInterval(this.timerInterval)
        this.timerInterval = null
    },
    timerEnd () {
        clearInterval(this.timerInterval)
        this.timerInterval = null
        this.timeLeft = 0
        this.running = false
        this.$emit('ended')
    },
    startTimer() {
      this.count = this.startingCount + 2
      this.timerInterval = setInterval(() => {
        this.count -= 1
        if (this.count <= this.startingCount) {
          this.blink = true
          setTimeout(() => this.blink=false,250)
        }
      }, 1000);
    },
  },
  mounted: function () {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* Sets the containers height and width */
.flashing_countdown_container {
  position: fixed;
  z-index: 12000;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
}

.wrapper {
  width: 100%;
  height: 100%;
}

.count {
  z-index: 100;
  font-family: gibsonsemibold;
  font-size: 60px;
  color: #ffffff;
  width: 100%;
  text-align: center;
}

.overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba(0,0,0,0.3);
}

.fill {
  background-color: rgba(255,255,255,0.05);
}
</style>
